
/* Fonts */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400');


.compare_add{
  display: flex;
  text-align: center;
  font-size: 12px;
  align-items: center;
  align-content:center;
  background-color: #e21e1e;
  vertical-align: center;
}
.text_id {
  width:90%;
  background-color: #f0f0f0;
  text-align: left;
  padding-top:5px;
  padding-bottom:10px;
  border: 1px solid #b6b3b3;
  font-size: 12px;
}

.byte_size {
  font-size: 12px;
  width: 100px;
  display:table;
  align-content:right;
  margin-left: auto;
  text-align: right;
  
}

.text_content_seperator{
  padding:8px;
  padding-top:20px;
  border: 0px solid #ffffff;
}
.text_content {
  width:90%;
  background-color: #ffffff;
  text-align: left;
  padding-top:5px;
  padding-bottom:10px;
  border: 1px solid #b8b8b8;
  font-size: 14px;
}

.text_content_row {
  width:90%;
  background-color: #ffffff;
  text-align: left;
  padding-top:5px;
  padding-bottom:10px;
  border: 1px solid #ffffff;
  font-size: 14px;
}

.content_table {
  width:90%;
  margin-left: 10px;
  background-color: #ffffff;
  text-align: left;
  border: 0px solid #ffffff;
  font-size: 14px;
  border-collapse: collapse;
}