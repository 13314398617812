@import url("https://fonts.googleapis.com/css?family=Roboto:300,400");

@media print {
  .documentTitle {
    margin-top: -36px !important;
  }
  .button_table {
    display: none;
  }
  .header {
    position: relative;
    display: block !important;
    z-index: -1  !important;
  }
  .footer {
    page-break-after: always;
  }
}

.content_table {
  display: none;
}
.button_table {
  width: 98%;
}

.drag-handle {
  cursor: move !important;
}
.error {
  color: #d81313;
  margin: 10px;
}

.validated_image {
  margin-top: -20px;
}

.logo {
  /* height: 150px; */
  padding-left: 5px;
  padding-top: 5px;
  width: 100px;
}

.documentTitle {
  padding-left: 30px;
  margin-top: 0px;
  color: #7f7f7f;
  font-weight: 500;
  font-size: 40px;
}

.header {
  display: none;
  width: 100%;
  padding-bottom: 20px;
}

.barTop {
  position: absolute;
  width: 300px;
  height: 30px;
  top: 50px;
  right: 20px;
  background-color: #d44b0c;
}

.barBottom {
  position: absolute;
  width: 330px;
  height: 18px;
  top: 85px;
  right: 10px;
  background-color: #f3c443;
}

.verticalBar {
  position: absolute;
  width: 90px;
  height: 500px;
  top: 10px;
  right: 100px;
  background-color: #e69635;
}

.blueprint {
  border-radius: 5px !important;
  color: #ffffff !important;
  background-color: #3c73ad !important;
  font-size: "10px" !important ;
}
.blueprint:disabled {
  background-color: #a6b8ccc7 !important;
}

.no_wrap {
  /* width: 100%; */
  display: grid;
  align-items: top;
  grid-template-columns: auto auto auto auto auto;
  /* ALSO POSSIBLE TO "ASSIGN" EXACT WIDTH
    grid-template-columns: 25% 25% 50%; */
}

.process_image {
  overflow: visible;
  z-index: 1000;
  position: relative;
  margin-top: -15px;
  margin-bottom: -40px;
  align-items: right;
  align-content: right;
  /* background-color: #f37d7d; */
}
.tooltip {
  border-bottom: 1px dotted #000000;
  color: #000000;
  outline: none;
  background-color: #aaaafa;
}

table.empty_table {
  margin: 0px;
  padding: 0px;
  border: 0px solid #ffffff;
  border-collapse: collapse;
}
table.empty_table td {
  border: none;
}

.clean-table tr {
  background-color: #fdfcfc;
}

.clean-table tr:nth-child(even) {
  background-color: #fdfcfc !important;
}

.question-table .ant-table table {
  border-collapse: collapse;
  border: 0px solid #ddd;
}
.question-table .ant-table table th {
  border: 1px solid rgb(243, 243, 243);
  background-color: #ffffff;
  color: #797979ab;
  font-weight: bold;
}

.question-table .ant-table table tr {
  vertical-align: top;
}
.question-table .ant-table table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.answer-table .ant-table table {
  border-collapse: collapse;
  border-left: 1px solid rgba(233, 233, 233, 0.623);
  border-right: 1px solid rgba(233, 233, 233, 0.623);
  border-bottom: 1px solid rgba(233, 233, 233, 0.623);
}

.answer-table .ant-table .ant-table-title {
  border-collapse: collapse;
  border: 1px solid rgba(233, 233, 233, 0.623);
  border-bottom: none;
}

.answer-table .ant-table table tr th {
  /* border-color: inherit; */
  padding-left: 20px;
  background-color: #ffffff;
  border-left: none;
  border-right: none;
  color: #797979ab;
  font-weight: bold;
  border-top: none;
}
.answer-table .ant-table table tr td {
  padding-top: 5px;
  padding-bottom: 5px;
}

.answer-table .ant-table table tr:nth-child(even) {
  background-color: #eeededda;
}
.answer-table .ant-table-tbody > tr.ant-table-row:hover > td {
  background: #eeededda;
  transition: color 0.002s;
}

.data-table .ant-table table {
  border-collapse: collapse;
  border: 0px solid #ddd;
}
.data-table .ant-table table th {
  border: 1px solid #ddd;
  background-color: #05529b;
  color: white;
}
.data-table .ant-table table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.my-list .ant-transfer-list-content-item > * {
  padding-top: 8px;
  flex: content;
  overflow: hidden;
}
.my-list .ant-transfer-list-header {
  background: #05529b;
  color: white;
}
.my-list .ant-transfer-list-content {
  overflow: hidden;
}

.my-list .ant-transfer-list {
  overflow: hidden;
}

.no-padding-list {
  padding-left: 1.2em;
  margin-bottom: 0;
}

.page {
  padding: 2px;
  /* font-size: 8px !important; */
}

.paper {
  background-color: #ffffff !important;
  margin: 5px;
  margin-bottom: 20px !important;
  border-radius: 3px !important;
  border-width: 1px !important;
  border-color: #ececec !important;
  border-style: solid;
}

.section_title {
  padding-left: 5px;
  color: #333;
  font-family: "Roboto" !important;
  font-size: 1.375rem !important;
  font-weight: 300 !important;
}

.profile_table {
  border: 0.5px solid #b8ac86;
}
.profile_table tr th {
  text-align: start;
  padding: 10px;
  background-color: #ede9d0 !important;
  color: #374140 !important;
  width: 20%;
  font-weight: 500 !important;
  border: 0.5px solid #b8ac86;
}
.profile_table tr td {
  padding: 10px;
  border: 0.5px solid #b8ac86;
}

table.styled_table {
  /* font-family: Arial, Helvetica, sans-serif; */
  border-collapse: collapse;
  width: 100%;
  border: 0.5px solid #ede9d0;
}

table.styled_table td,
table.styled_table th {
  border: 0.5px solid #b8ac86;
  padding: 8px;
}
table.styled_table tr td {
  padding-bottom: 0px;
}
table.styled_table th {
  padding: 12px;
  text-align: left;
  font-weight: 500;
  background-color: #ede9d0;
  color: #374140;
}

table.styled_table tr:nth-child(even) {
  background-color: #f5f5f59d;
}

table.styled_table tr:hover {
  background-color: #ddd;
}

.question_section_heading {
  font-weight: 600 !important;
}
