.recommend_table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
    border: 0.5px solid #0d6baf;
    font-size: 12px;
    font-weight: 500 !important;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }
  .recommend_table tr th {
    border: 0.5px solid #4dabec;
  }
  .recommend_table tr td {
    border: 0.5px solid #4dabec;
  }
  
  .type_name {
    background-color: #ffffff;
    color: #000000;
    padding: 10px;
    text-align: center;
  }
  
  .group_category_name {
    width: 10%;
    background-color: #ff7e00;
    text-align: center;
    border: 1px solid #4dabec;
    border-collapse: collapse;
  }
  
  .group_recommendation_cell {
    background-color: #fafdfd;
    vertical-align: top;
  }
  .group_recommendation_list {
    align-content: top;
    margin-left: 0px;
    padding-left: 20px;
    background-color: #fafdfd;
    text-align: left;
    vertical-align: top;
    justify-content: top;
    min-width: 200px;
  }
  
  .recommend_options_heading {
    width: 20%;
    text-align: center;
    background-color: #ffc17a;
    padding: 10px;
    border: 1px solid #4dabec;
  }
  
  .recommend_groups_heading {
    width: 20%;
    text-align: center;
    background-color: #0d6baf;
    padding: 10px;
  }
  
  .group_name {
    background-color: #0d6baf;
    color: #fafafa;
    width: 130px;
    min-width: 110px;
    max-width: 200px;
    padding: 10px;
    text-align: center;
  }
  
  .category_name {
    width: 11%;
    min-width: 110px;
    background-color: #c0e8ff;
    text-align: center;
  }
  
  .recommendation_cell {
    background-color: #fafdfd;
    vertical-align: top;
  }
  
  .recommend_group_seperator {
    background-color: #ffffff;
    width: 100%;
    text-align: bottom;
    font-size: 24px;
    padding-bottom: 5px;
    padding-top: 50px;
  }
  .recommendation_list {
    align-content: top;
    margin-left: 0px;
    padding-left: 20px;
    padding-top: 5px;
    background-color: #fafdfd;
    text-align: left;
    vertical-align: top;
    justify-content: top;
    font-weight: 500 !important;
  }