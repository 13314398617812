/* Fonts */
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400");

.edit_rule_icon {
  width: "2px";
  height: 2px;
  padding: 0px;
  margin: 0px;
}

.edit_rule_button {
  padding: 0px;
  margin: 0px;
}


.table {
  width: 100%;
  margin-top: 20px;
  background-color: #d1ccce;
  font-size: 10px;
}
.th {
  font-size: 10px;
}



.title {
  background-color: #ffffff;
  padding: 15px;
  text-align: center;
}

.question_id {
  margin-top: 20px;
  width: 100%;
  text-align: left;
  border: 0px solid rgb(30, 60, 194);
  font-size: 14px;
  font-family: Calibri;
  border-collapse: collapse;
}
.question {
  width: 100%;
  margin-left: 10px;
  text-align: left;
  margin-bottom: 0px;
  border: 0px solid rgb(30, 60, 194);
  font-size: 14px;
  font-family: Calibri;
  border-collapse: collapse;
}
.answer_list {
  align-content: top;
  margin-left: 20px;
  margin-top: 0px;
  padding-left: 20px;
  background-color: #fafdfd;
  text-align: left;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}

.trigger_table {
  width: 100%;
  margin-top: 20px;
  background-color: #ececec;
  border: 1px solid rgb(182, 186, 204);
  font-size: 10px;
  border-collapse: collapse;
}

.detect_table {
  width: 100%;
  margin-top: 20px;
  background-color: #ececec;
  border: 0.5px solid rgb(182, 186, 204);
  font-size: 10px;
  border-collapse: collapse;
}
.symptom_row {
  width: 100%;
  border: 1px solid rgb(196, 204, 241);
  border-collapse: collapse;
}
.symptom_title {
  margin-top: 0px;
  padding: 5px;
  background-color: #c0e1f7;
  border: 1px solid rgb(210, 211, 218);
  text-align: center;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}

.trigger_symptom {
  align-content: top;
  padding: 5px;
  background-color: #ffffff;
  border: 1px solid rgb(196, 197, 202);
  text-align: left;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}
.trigger_symptom_model {
  align-content: top;
  padding: 5px;
  background-color: #ffffff;
  border: 1px solid rgb(196, 197, 202);
  text-align: left;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}
.trigger_symptom_detail {
  align-content: top;
  padding: 5px;
  background-color: #f8f6f6;
  border: 1px solid rgb(196, 197, 202);
  text-align: left;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}

.detect_trigger_symptom {
  align-content: top;
  padding: 5px;
  background-color: #f8f6f6;
  border: 1px solid rgb(196, 197, 202);
  text-align: left;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}

.detect_trigger_symptom_detail {
  align-content: top;
  padding: 5px;
  background-color: #f8f6f6;
  border: 1px solid rgb(196, 197, 202);
  text-align: left;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}

.detect_trigger_symptom_detail_center {
  align-content: top;
  padding: 5px;
  background-color: #f8f6f6;
  border: 1px solid rgb(196, 197, 202);
  text-align: center;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}
.detect_seperator {
  background-color: #ffffff;
  width: 100%;
  text-align: bottom;
  border: 1px solid rgb(255, 255, 255);
  font-size: 24px;
  padding-bottom: 5px;
  padding-top: 50px;
}
.detect_symptom_title {
  margin-top: 0px;
  padding: 5px;
  background-color: #d7d8d8;
  border: 1px solid rgb(210, 211, 218);
  text-align: center;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}
.detect_symptom {
  align-content: top;
  padding: 5px;
  background-color: #ffffff;
  border: 1px solid rgb(196, 197, 202);
  text-align: left;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}

.symptom_detail {
  align-content: top;
  padding: 5px;
  background-color: #ffffff;
  border: 1px solid rgb(196, 197, 202);
  text-align: left;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}

.symptom_detail_center {
  align-content: center;
  padding: 5px;
  background-color: #ffffff;
  border: 1px solid rgb(196, 197, 202);
  text-align: center;
  font-family: Calibri;
  vertical-align: center;
  font-size: 14px;
  justify-content: center;
}

.question_id_list {
  align-content: top;
  margin-left: 10px;
  margin-bottom: 2px;
  margin-top: 2px;
  margin-right: 2px;
  padding-left: 0px;
  padding-right: 0px;
  text-align: left;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}

.question_id_list_item {
  align-content: top;
  margin-left: 10px;
  margin-bottom: 0px;
  margin-top: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
  text-align: left;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}

.detect_rule_model_table {
  width: 100%;
  /* margin: 10px; */
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  border: 0.5px solid #b8ac86;
}

.detect_rule_model_table tr th {
  border: 0.5px solid #b8ac86;
}

.detect_rule_model_table tr td {
  border: 0.5px solid #b8ac86;
}

.break {
  background: #3e87bc;
  height: 4px;
  margin: 5px 0 10px 0;
  width: 100%;
}

.detect_rule_model_header {
  background-color: #EDE9D0;
  font-weight: 600 ;
  color: #374140 ;
  padding: 8px;
}

.detect_rule_model_seperator {
  padding: 8px;
  padding-top: 20px;
}
.detect_rule_model_grade {
  background-color: #c8dbf8;
  color: #003f0f;
  padding: 8px;
}
.detect_rule_model_alert {
  background-color: #effffd;
  color: #003f0f;
  padding: 8px;
  text-align: center;
}

.detect_rule_model_alert_green {
  background-color: #098b34;
  color: #ffffff;
  padding: 8px;
  text-align: center;
}

.detect_rule_model_alert_yellow {
  background-color: #f8f82c;
  padding: 8px;
  text-align: center;
}

.detect_rule_model_alert_orange {
  background-color: #ff6600;
  color: #ffffff;
  padding: 8px;
  text-align: center;
}
.detect_rule_model_alert_red {
  background-color: #e4041a;
  color: #ffffff;
  padding: 8px;
  text-align: center;
}

.detect_rule_model_priority {
  background-color: #d6cfc2;
  color: #003f0f;
  padding: 8px;
  text-align: center;
}
.detect_rule_model_primary {
  background-color: #ffffff;
  color: #e4041a;
  padding: 8px;
  text-align: center;
}
.detect_rule_model_associated {
  background-color: #ffffff;
  color: #0059ff;
  padding: 8px;
  text-align: center;
}
.detect_rule_model_clinical {
  background-color: #ffffff;
  color: #007710;
  padding: 8px;
  text-align: center;
}

.validation_report_table {
  background-color: #ffffff;
}

.validation_report_model_seperator {
  background-color: #ffffff;
  color: #220072;
  border: 1px solid rgb(255, 255, 255);
  padding: 8px;
}
.validation_report_model_header {
  background-color: #ffffff;
  color: #220072;
  border: 1px solid rgb(255, 255, 255);
  padding: 8px;
  text-align: center;
}

.validation_report_symptom_heading {
  background-color: #07a507;
  color: #ffffff;
  border: 1px solid rgb(40, 42, 51);
  padding: 8px;
  text-align: center;
}

.validation_report_heading {
  background-color: #dbdbdb;
  color: #220072;
  border: 1px solid rgb(40, 42, 51);
  padding: 8px;
  text-align: center;
}
.validation_report_symptom {
  background-color: #ffffff;
  width: 20%;
  color: #220072;
  border: 1px solid rgb(40, 42, 51);
  padding: 8px;
  text-align: left;
}

.validation_report_symptom_row {
  border: 1px solid rgb(40, 42, 51);
  padding: 8px;
  text-align: left;
}
.validation_report_no_answer {
  background-color: #f0aeae;
  color: #ee0000;
  border: 1px solid rgb(40, 42, 51);
  padding: 8px;
  text-align: left;
}

.validation_report_answer {
  background-color: #ffffff;
  color: #220072;
  border: 1px solid rgb(40, 42, 51);
  padding: 8px;
  text-align: left;
}

.recommendation_groups_table {
  width: 100%;
  margin-top: 20px;
  background-color: #ececec;
  border: 1px solid rgb(182, 186, 204);
  font-size: 10px;
  border-collapse: collapse;
}
