
/* Fonts */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400');

.system-heading {
  max-width:  100%;
  width:100%;
  height: 30px;
  display: inline-block;
  padding: 0px 10px 0px 10px;
  text-align: center;
  background-color: #ffffff;
  color: rgb(3, 0, 12);
  font-family: 'Roboto', sans-serif;
  font-size: 25px;
  border: 1px;
  border-radius: 5px;
  padding-right: 10px;
}
.recommend-group {
  max-width:  100%;
  margin:10px;
  display: inline-block;
  padding: 0px 10px 0px 10px;
  text-align: center;
  background-color: #ffc17a;
  color: rgb(3, 0, 12);
  font-family: 'proxima-nova';
  font-size: 25px;
  border: 1px;
  border-radius: 5px;
  padding-right: 10px;
}

.groups {
  max-width:  100%;
  display: inline-block;
  padding: 0px 10px 0px 10px;
  text-align: center;
  background-color: #2bff00;
  color: rgb(3, 0, 12);
  font-family: 'proxima-nova';
  font-size: 25px;
  border: 1px;
  border-radius: 5px;
  padding-right: 10px;
}
.group-heading {
  height: 30px;
  text-align: center;
  vertical-align: center;
  color: rgb(3, 0, 12);
  font-family: 'proxima-nova';
  font-size: 20px;
}

.grid-paper {
  max-width:100%;
  width:300px;
  height:500px;
  display: inline-block;
  padding: 0px 10px 0px 10px;
  text-align: center;
  background-color: #6e4903;
  color: rgb(3, 0, 12);
  font-family: 'proxima-nova';
  font-size: 25px;
  border: 1px;
  border-radius: 5px;
  padding-right: 10px;
}


.layoutRoot {
  display: flex;
  flex-wrap: wrap;
  margin-left:10;
}

.sortable-tree {
  display: inline-block;
  width: 900px;
  height: 900px;
  flex-wrap: flex;
  overflow: hidden;
  padding: 0px 0px 0px 0px;
  text-align: center;
  color: rgb(64, 0, 255);
  font-family: 'proxima-nova';
  font-size: 25px;
  border: 0px solid rgb(0, 225, 255);
  border-radius: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}

.box {
  width: 100%;
  height: 100%;
  min-height: 100;
  overflow: hidden;
  position: relative;
  display: inline-block;
  background-color: #b10202;
  text-align: center;
  padding: 2px;
  padding-bottom: 10px;
  padding-right: 20px;

  margin-bottom: 20px;
 
  margin: 0px;
}
.box .text {
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 36px;
}
.box3 .react-resizable-handle {
  display: none;
}
.box3:hover .react-resizable-handle {
  display: block;
}
.grid-item {
  width:30%;
  height:900px;
  display: inline-block;
  padding: 0px 0px 0px 0px;
  text-align: center;
  background-color: #ffffff;
  color: rgb(3, 0, 12);
  font-family: 'proxima-nova';
  font-size: 25px;
  border: 0px solid rgb(255, 255, 255);
  border-radius: 5px;
  padding-right: 0px;
}
.altHandle .rst__moveHandle {
  background: red;
}
.rst__rowContents {
  background-color: rgb(241, 0, 60);
}
.node-item {
  height: 40px;
  white-space: pre-wrap;
  background-color: rgb(43, 255, 0);
  color: rgb(3, 0, 12);
  border: 0px solid rgb(70, 0, 0);
}

.node-input {
  font-family: 'Roboto', sans-serif;
  font-size: 10px;
  white-space: pre-wrap;
  background-color: #fafafa;
  width: 400px;
  height:40px;
  border: 0px solid rgb(70, 0, 0);
}
.react-resizable {
  background-color: #f1eeee;
  border: 1px solid rgb(192, 191, 194);

}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  border: 0px solid rgb(255, 0, 234);

  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}