@import url(https://fonts.googleapis.com/css?family=Roboto:300,400);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}


/* Fonts */


.compare_add{
  display: flex;
  text-align: center;
  font-size: 12px;
  align-items: center;
  align-content:center;
  background-color: #e21e1e;
  vertical-align: center;
}
.text_id {
  width:90%;
  background-color: #f0f0f0;
  text-align: left;
  padding-top:5px;
  padding-bottom:10px;
  border: 1px solid #b6b3b3;
  font-size: 12px;
}

.byte_size {
  font-size: 12px;
  width: 100px;
  display:table;
  align-content:right;
  margin-left: auto;
  text-align: right;
  
}

.text_content_seperator{
  padding:8px;
  padding-top:20px;
  border: 0px solid #ffffff;
}
.text_content {
  width:90%;
  background-color: #ffffff;
  text-align: left;
  padding-top:5px;
  padding-bottom:10px;
  border: 1px solid #b8b8b8;
  font-size: 14px;
}

.text_content_row {
  width:90%;
  background-color: #ffffff;
  text-align: left;
  padding-top:5px;
  padding-bottom:10px;
  border: 1px solid #ffffff;
  font-size: 14px;
}

.content_table {
  width:90%;
  margin-left: 10px;
  background-color: #ffffff;
  text-align: left;
  border: 0px solid #ffffff;
  font-size: 14px;
  border-collapse: collapse;
}

/* Fonts */

.system-heading {
  max-width:  100%;
  width:100%;
  height: 30px;
  display: inline-block;
  padding: 0px 10px 0px 10px;
  text-align: center;
  background-color: #ffffff;
  color: rgb(3, 0, 12);
  font-family: 'Roboto', sans-serif;
  font-size: 25px;
  border: 1px;
  border-radius: 5px;
  padding-right: 10px;
}
.recommend-group {
  max-width:  100%;
  margin:10px;
  display: inline-block;
  padding: 0px 10px 0px 10px;
  text-align: center;
  background-color: #ffc17a;
  color: rgb(3, 0, 12);
  font-family: 'proxima-nova';
  font-size: 25px;
  border: 1px;
  border-radius: 5px;
  padding-right: 10px;
}

.groups {
  max-width:  100%;
  display: inline-block;
  padding: 0px 10px 0px 10px;
  text-align: center;
  background-color: #2bff00;
  color: rgb(3, 0, 12);
  font-family: 'proxima-nova';
  font-size: 25px;
  border: 1px;
  border-radius: 5px;
  padding-right: 10px;
}
.group-heading {
  height: 30px;
  text-align: center;
  vertical-align: center;
  color: rgb(3, 0, 12);
  font-family: 'proxima-nova';
  font-size: 20px;
}

.grid-paper {
  max-width:100%;
  width:300px;
  height:500px;
  display: inline-block;
  padding: 0px 10px 0px 10px;
  text-align: center;
  background-color: #6e4903;
  color: rgb(3, 0, 12);
  font-family: 'proxima-nova';
  font-size: 25px;
  border: 1px;
  border-radius: 5px;
  padding-right: 10px;
}


.layoutRoot {
  display: flex;
  flex-wrap: wrap;
  margin-left:10;
}

.sortable-tree {
  display: inline-block;
  width: 900px;
  height: 900px;
  flex-wrap: flex;
  overflow: hidden;
  padding: 0px 0px 0px 0px;
  text-align: center;
  color: rgb(64, 0, 255);
  font-family: 'proxima-nova';
  font-size: 25px;
  border: 0px solid rgb(0, 225, 255);
  border-radius: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}

.box {
  width: 100%;
  height: 100%;
  min-height: 100;
  overflow: hidden;
  position: relative;
  display: inline-block;
  background-color: #b10202;
  text-align: center;
  padding: 2px;
  padding-bottom: 10px;
  padding-right: 20px;

  margin-bottom: 20px;
 
  margin: 0px;
}
.box .text {
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 36px;
}
.box3 .react-resizable-handle {
  display: none;
}
.box3:hover .react-resizable-handle {
  display: block;
}
.grid-item {
  width:30%;
  height:900px;
  display: inline-block;
  padding: 0px 0px 0px 0px;
  text-align: center;
  background-color: #ffffff;
  color: rgb(3, 0, 12);
  font-family: 'proxima-nova';
  font-size: 25px;
  border: 0px solid rgb(255, 255, 255);
  border-radius: 5px;
  padding-right: 0px;
}
.altHandle .rst__moveHandle {
  background: red;
}
.rst__rowContents {
  background-color: rgb(241, 0, 60);
}
.node-item {
  height: 40px;
  white-space: pre-wrap;
  background-color: rgb(43, 255, 0);
  color: rgb(3, 0, 12);
  border: 0px solid rgb(70, 0, 0);
}

.node-input {
  font-family: 'Roboto', sans-serif;
  font-size: 10px;
  white-space: pre-wrap;
  background-color: #fafafa;
  width: 400px;
  height:40px;
  border: 0px solid rgb(70, 0, 0);
}
.react-resizable {
  background-color: #f1eeee;
  border: 1px solid rgb(192, 191, 194);

}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  border: 0px solid rgb(255, 0, 234);

  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}
/* Fonts */

.edit_rule_icon {
  width: "2px";
  height: 2px;
  padding: 0px;
  margin: 0px;
}

.edit_rule_button {
  padding: 0px;
  margin: 0px;
}


.table {
  width: 100%;
  margin-top: 20px;
  background-color: #d1ccce;
  font-size: 10px;
}
.th {
  font-size: 10px;
}



.title {
  background-color: #ffffff;
  padding: 15px;
  text-align: center;
}

.question_id {
  margin-top: 20px;
  width: 100%;
  text-align: left;
  border: 0px solid rgb(30, 60, 194);
  font-size: 14px;
  font-family: Calibri;
  border-collapse: collapse;
}
.question {
  width: 100%;
  margin-left: 10px;
  text-align: left;
  margin-bottom: 0px;
  border: 0px solid rgb(30, 60, 194);
  font-size: 14px;
  font-family: Calibri;
  border-collapse: collapse;
}
.answer_list {
  align-content: top;
  margin-left: 20px;
  margin-top: 0px;
  padding-left: 20px;
  background-color: #fafdfd;
  text-align: left;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}

.trigger_table {
  width: 100%;
  margin-top: 20px;
  background-color: #ececec;
  border: 1px solid rgb(182, 186, 204);
  font-size: 10px;
  border-collapse: collapse;
}

.detect_table {
  width: 100%;
  margin-top: 20px;
  background-color: #ececec;
  border: 0.5px solid rgb(182, 186, 204);
  font-size: 10px;
  border-collapse: collapse;
}
.symptom_row {
  width: 100%;
  border: 1px solid rgb(196, 204, 241);
  border-collapse: collapse;
}
.symptom_title {
  margin-top: 0px;
  padding: 5px;
  background-color: #c0e1f7;
  border: 1px solid rgb(210, 211, 218);
  text-align: center;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}

.trigger_symptom {
  align-content: top;
  padding: 5px;
  background-color: #ffffff;
  border: 1px solid rgb(196, 197, 202);
  text-align: left;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}
.trigger_symptom_model {
  align-content: top;
  padding: 5px;
  background-color: #ffffff;
  border: 1px solid rgb(196, 197, 202);
  text-align: left;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}
.trigger_symptom_detail {
  align-content: top;
  padding: 5px;
  background-color: #f8f6f6;
  border: 1px solid rgb(196, 197, 202);
  text-align: left;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}

.detect_trigger_symptom {
  align-content: top;
  padding: 5px;
  background-color: #f8f6f6;
  border: 1px solid rgb(196, 197, 202);
  text-align: left;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}

.detect_trigger_symptom_detail {
  align-content: top;
  padding: 5px;
  background-color: #f8f6f6;
  border: 1px solid rgb(196, 197, 202);
  text-align: left;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}

.detect_trigger_symptom_detail_center {
  align-content: top;
  padding: 5px;
  background-color: #f8f6f6;
  border: 1px solid rgb(196, 197, 202);
  text-align: center;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}
.detect_seperator {
  background-color: #ffffff;
  width: 100%;
  text-align: bottom;
  border: 1px solid rgb(255, 255, 255);
  font-size: 24px;
  padding-bottom: 5px;
  padding-top: 50px;
}
.detect_symptom_title {
  margin-top: 0px;
  padding: 5px;
  background-color: #d7d8d8;
  border: 1px solid rgb(210, 211, 218);
  text-align: center;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}
.detect_symptom {
  align-content: top;
  padding: 5px;
  background-color: #ffffff;
  border: 1px solid rgb(196, 197, 202);
  text-align: left;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}

.symptom_detail {
  align-content: top;
  padding: 5px;
  background-color: #ffffff;
  border: 1px solid rgb(196, 197, 202);
  text-align: left;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}

.symptom_detail_center {
  align-content: center;
  padding: 5px;
  background-color: #ffffff;
  border: 1px solid rgb(196, 197, 202);
  text-align: center;
  font-family: Calibri;
  vertical-align: center;
  font-size: 14px;
  justify-content: center;
}

.question_id_list {
  align-content: top;
  margin-left: 10px;
  margin-bottom: 2px;
  margin-top: 2px;
  margin-right: 2px;
  padding-left: 0px;
  padding-right: 0px;
  text-align: left;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}

.question_id_list_item {
  align-content: top;
  margin-left: 10px;
  margin-bottom: 0px;
  margin-top: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
  text-align: left;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}

.detect_rule_model_table {
  width: 100%;
  /* margin: 10px; */
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  border: 0.5px solid #b8ac86;
}

.detect_rule_model_table tr th {
  border: 0.5px solid #b8ac86;
}

.detect_rule_model_table tr td {
  border: 0.5px solid #b8ac86;
}

.break {
  background: #3e87bc;
  height: 4px;
  margin: 5px 0 10px 0;
  width: 100%;
}

.detect_rule_model_header {
  background-color: #EDE9D0;
  font-weight: 600 ;
  color: #374140 ;
  padding: 8px;
}

.detect_rule_model_seperator {
  padding: 8px;
  padding-top: 20px;
}
.detect_rule_model_grade {
  background-color: #c8dbf8;
  color: #003f0f;
  padding: 8px;
}
.detect_rule_model_alert {
  background-color: #effffd;
  color: #003f0f;
  padding: 8px;
  text-align: center;
}

.detect_rule_model_alert_green {
  background-color: #098b34;
  color: #ffffff;
  padding: 8px;
  text-align: center;
}

.detect_rule_model_alert_yellow {
  background-color: #f8f82c;
  padding: 8px;
  text-align: center;
}

.detect_rule_model_alert_orange {
  background-color: #ff6600;
  color: #ffffff;
  padding: 8px;
  text-align: center;
}
.detect_rule_model_alert_red {
  background-color: #e4041a;
  color: #ffffff;
  padding: 8px;
  text-align: center;
}

.detect_rule_model_priority {
  background-color: #d6cfc2;
  color: #003f0f;
  padding: 8px;
  text-align: center;
}
.detect_rule_model_primary {
  background-color: #ffffff;
  color: #e4041a;
  padding: 8px;
  text-align: center;
}
.detect_rule_model_associated {
  background-color: #ffffff;
  color: #0059ff;
  padding: 8px;
  text-align: center;
}
.detect_rule_model_clinical {
  background-color: #ffffff;
  color: #007710;
  padding: 8px;
  text-align: center;
}

.validation_report_table {
  background-color: #ffffff;
}

.validation_report_model_seperator {
  background-color: #ffffff;
  color: #220072;
  border: 1px solid rgb(255, 255, 255);
  padding: 8px;
}
.validation_report_model_header {
  background-color: #ffffff;
  color: #220072;
  border: 1px solid rgb(255, 255, 255);
  padding: 8px;
  text-align: center;
}

.validation_report_symptom_heading {
  background-color: #07a507;
  color: #ffffff;
  border: 1px solid rgb(40, 42, 51);
  padding: 8px;
  text-align: center;
}

.validation_report_heading {
  background-color: #dbdbdb;
  color: #220072;
  border: 1px solid rgb(40, 42, 51);
  padding: 8px;
  text-align: center;
}
.validation_report_symptom {
  background-color: #ffffff;
  width: 20%;
  color: #220072;
  border: 1px solid rgb(40, 42, 51);
  padding: 8px;
  text-align: left;
}

.validation_report_symptom_row {
  border: 1px solid rgb(40, 42, 51);
  padding: 8px;
  text-align: left;
}
.validation_report_no_answer {
  background-color: #f0aeae;
  color: #ee0000;
  border: 1px solid rgb(40, 42, 51);
  padding: 8px;
  text-align: left;
}

.validation_report_answer {
  background-color: #ffffff;
  color: #220072;
  border: 1px solid rgb(40, 42, 51);
  padding: 8px;
  text-align: left;
}

.recommendation_groups_table {
  width: 100%;
  margin-top: 20px;
  background-color: #ececec;
  border: 1px solid rgb(182, 186, 204);
  font-size: 10px;
  border-collapse: collapse;
}


/* Fonts */

.question-ids {
  max-width:  100%;
  width: 550px;
  display: inline-block;
  padding: 0px 10px 0px 10px;
  text-align: left;
  background-color: #ffffff;
  color: rgb(1, 55, 126);
  font-family: 'Roboto', sans-serif;
  font-size: 10px;
  font-weight: bold;
  border: 1px;
 
  margin: 5;
  border-radius: 5px;
  padding-right: 10px;
}

.recommend_table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
    border: 0.5px solid #0d6baf;
    font-size: 12px;
    font-weight: 500 !important;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
            hyphens: auto;
  }
  .recommend_table tr th {
    border: 0.5px solid #4dabec;
  }
  .recommend_table tr td {
    border: 0.5px solid #4dabec;
  }
  
  .type_name {
    background-color: #ffffff;
    color: #000000;
    padding: 10px;
    text-align: center;
  }
  
  .group_category_name {
    width: 10%;
    background-color: #ff7e00;
    text-align: center;
    border: 1px solid #4dabec;
    border-collapse: collapse;
  }
  
  .group_recommendation_cell {
    background-color: #fafdfd;
    vertical-align: top;
  }
  .group_recommendation_list {
    align-content: top;
    margin-left: 0px;
    padding-left: 20px;
    background-color: #fafdfd;
    text-align: left;
    vertical-align: top;
    justify-content: top;
    min-width: 200px;
  }
  
  .recommend_options_heading {
    width: 20%;
    text-align: center;
    background-color: #ffc17a;
    padding: 10px;
    border: 1px solid #4dabec;
  }
  
  .recommend_groups_heading {
    width: 20%;
    text-align: center;
    background-color: #0d6baf;
    padding: 10px;
  }
  
  .group_name {
    background-color: #0d6baf;
    color: #fafafa;
    width: 130px;
    min-width: 110px;
    max-width: 200px;
    padding: 10px;
    text-align: center;
  }
  
  .category_name {
    width: 11%;
    min-width: 110px;
    background-color: #c0e8ff;
    text-align: center;
  }
  
  .recommendation_cell {
    background-color: #fafdfd;
    vertical-align: top;
  }
  
  .recommend_group_seperator {
    background-color: #ffffff;
    width: 100%;
    text-align: bottom;
    font-size: 24px;
    padding-bottom: 5px;
    padding-top: 50px;
  }
  .recommendation_list {
    align-content: top;
    margin-left: 0px;
    padding-left: 20px;
    padding-top: 5px;
    background-color: #fafdfd;
    text-align: left;
    vertical-align: top;
    justify-content: top;
    font-weight: 500 !important;
  }
@media print {
  .documentTitle {
    margin-top: -36px !important;
  }
  .button_table {
    display: none;
  }
  .header {
    position: relative;
    display: block !important;
    z-index: -1  !important;
  }
  .footer {
    page-break-after: always;
  }
}

.content_table {
  display: none;
}
.button_table {
  width: 98%;
}

.drag-handle {
  cursor: move !important;
}
.error {
  color: #d81313;
  margin: 10px;
}

.validated_image {
  margin-top: -20px;
}

.logo {
  /* height: 150px; */
  padding-left: 5px;
  padding-top: 5px;
  width: 100px;
}

.documentTitle {
  padding-left: 30px;
  margin-top: 0px;
  color: #7f7f7f;
  font-weight: 500;
  font-size: 40px;
}

.header {
  display: none;
  width: 100%;
  padding-bottom: 20px;
}

.barTop {
  position: absolute;
  width: 300px;
  height: 30px;
  top: 50px;
  right: 20px;
  background-color: #d44b0c;
}

.barBottom {
  position: absolute;
  width: 330px;
  height: 18px;
  top: 85px;
  right: 10px;
  background-color: #f3c443;
}

.verticalBar {
  position: absolute;
  width: 90px;
  height: 500px;
  top: 10px;
  right: 100px;
  background-color: #e69635;
}

.blueprint {
  border-radius: 5px !important;
  color: #ffffff !important;
  background-color: #3c73ad !important;
  font-size: "10px" !important ;
}
.blueprint:disabled {
  background-color: #a6b8ccc7 !important;
}

.no_wrap {
  /* width: 100%; */
  display: grid;
  align-items: top;
  grid-template-columns: auto auto auto auto auto;
  /* ALSO POSSIBLE TO "ASSIGN" EXACT WIDTH
    grid-template-columns: 25% 25% 50%; */
}

.process_image {
  overflow: visible;
  z-index: 1000;
  position: relative;
  margin-top: -15px;
  margin-bottom: -40px;
  align-items: right;
  align-content: right;
  /* background-color: #f37d7d; */
}
.tooltip {
  border-bottom: 1px dotted #000000;
  color: #000000;
  outline: none;
  background-color: #aaaafa;
}

table.empty_table {
  margin: 0px;
  padding: 0px;
  border: 0px solid #ffffff;
  border-collapse: collapse;
}
table.empty_table td {
  border: none;
}

.clean-table tr {
  background-color: #fdfcfc;
}

.clean-table tr:nth-child(even) {
  background-color: #fdfcfc !important;
}

.question-table .ant-table table {
  border-collapse: collapse;
  border: 0px solid #ddd;
}
.question-table .ant-table table th {
  border: 1px solid rgb(243, 243, 243);
  background-color: #ffffff;
  color: #797979ab;
  font-weight: bold;
}

.question-table .ant-table table tr {
  vertical-align: top;
}
.question-table .ant-table table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.answer-table .ant-table table {
  border-collapse: collapse;
  border-left: 1px solid rgba(233, 233, 233, 0.623);
  border-right: 1px solid rgba(233, 233, 233, 0.623);
  border-bottom: 1px solid rgba(233, 233, 233, 0.623);
}

.answer-table .ant-table .ant-table-title {
  border-collapse: collapse;
  border: 1px solid rgba(233, 233, 233, 0.623);
  border-bottom: none;
}

.answer-table .ant-table table tr th {
  /* border-color: inherit; */
  padding-left: 20px;
  background-color: #ffffff;
  border-left: none;
  border-right: none;
  color: #797979ab;
  font-weight: bold;
  border-top: none;
}
.answer-table .ant-table table tr td {
  padding-top: 5px;
  padding-bottom: 5px;
}

.answer-table .ant-table table tr:nth-child(even) {
  background-color: #eeededda;
}
.answer-table .ant-table-tbody > tr.ant-table-row:hover > td {
  background: #eeededda;
  transition: color 0.002s;
}

.data-table .ant-table table {
  border-collapse: collapse;
  border: 0px solid #ddd;
}
.data-table .ant-table table th {
  border: 1px solid #ddd;
  background-color: #05529b;
  color: white;
}
.data-table .ant-table table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.my-list .ant-transfer-list-content-item > * {
  padding-top: 8px;
  flex: content;
  overflow: hidden;
}
.my-list .ant-transfer-list-header {
  background: #05529b;
  color: white;
}
.my-list .ant-transfer-list-content {
  overflow: hidden;
}

.my-list .ant-transfer-list {
  overflow: hidden;
}

.no-padding-list {
  padding-left: 1.2em;
  margin-bottom: 0;
}

.page {
  padding: 2px;
  /* font-size: 8px !important; */
}

.paper {
  background-color: #ffffff !important;
  margin: 5px;
  margin-bottom: 20px !important;
  border-radius: 3px !important;
  border-width: 1px !important;
  border-color: #ececec !important;
  border-style: solid;
}

.section_title {
  padding-left: 5px;
  color: #333;
  font-family: "Roboto" !important;
  font-size: 1.375rem !important;
  font-weight: 300 !important;
}

.profile_table {
  border: 0.5px solid #b8ac86;
}
.profile_table tr th {
  text-align: start;
  padding: 10px;
  background-color: #ede9d0 !important;
  color: #374140 !important;
  width: 20%;
  font-weight: 500 !important;
  border: 0.5px solid #b8ac86;
}
.profile_table tr td {
  padding: 10px;
  border: 0.5px solid #b8ac86;
}

table.styled_table {
  /* font-family: Arial, Helvetica, sans-serif; */
  border-collapse: collapse;
  width: 100%;
  border: 0.5px solid #ede9d0;
}

table.styled_table td,
table.styled_table th {
  border: 0.5px solid #b8ac86;
  padding: 8px;
}
table.styled_table tr td {
  padding-bottom: 0px;
}
table.styled_table th {
  padding: 12px;
  text-align: left;
  font-weight: 500;
  background-color: #ede9d0;
  color: #374140;
}

table.styled_table tr:nth-child(even) {
  background-color: #f5f5f59d;
}

table.styled_table tr:hover {
  background-color: #ddd;
}

.question_section_heading {
  font-weight: 600 !important;
}

.App {
  text-align: center;
}

.App-logo {
  /* animation: App-logo-spin infinite 20s linear; */
  height: 250px;
  margin: 2em;
}

.App-header {
  background-color: #880957;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.display-linebreak {
    white-space: pre-line;
}
