
/* Fonts */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400');

.question-ids {
  max-width:  100%;
  width: 550px;
  display: inline-block;
  padding: 0px 10px 0px 10px;
  text-align: left;
  background-color: #ffffff;
  color: rgb(1, 55, 126);
  font-family: 'Roboto', sans-serif;
  font-size: 10px;
  font-weight: bold;
  border: 1px;
 
  margin: 5;
  border-radius: 5px;
  padding-right: 10px;
}
